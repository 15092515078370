<template>
  <article class="uk-comment uk-visible-toggle" tabindex="-1">
    <header class="uk-comment-header uk-position-relative">
      <div class="uk-grid-medium uk-flex-middle uk-grid" uk-grid="">
        <div class="uk-width-auto uk-first-column">
          <img class="uk-border-circle" width="40" height="40" :src="comment.user.photo_link" />
        </div>
        <div class="uk-width-expand">
          <h6 class="h6 mt-0 mb-0">
            {{ comment.user.name + " " + comment.user.surname }}
          </h6>
          <p class="uk-text-meta uk-margin-remove-top">
            <time datetime="2016-04-01T19:00">@{{
              comment.user.name.toLowerCase() +
              "." +
              comment.user.surname.toLowerCase()
            }}</time>
          </p>
        </div>
        <div class="uk-width-auto">
          <div class="text-muted h7 mb-2">
            <i class="uil-clock"></i>{{ timeFromNow(comment.created_at) }}
          </div>
        </div>
      </div>
    </header>
    <div class="uk-comment-body mb-2">
      <p>{{ comment.text }}</p>
      <p class="mb-3 ml-2" v-if="comment.status_id === 1">
        <strong>(Yönetici Onayına Gönderildi)</strong>
      </p>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <!-- <a href="javascript:void(0)" @click="openCommentArea" class="card-link"><i class="uil-comment"></i> Cevap verin</a> -->
      <a v-if="comment.user_id == current_user.id" href="javascript:void(0)" @click="deleteComment" class="card-link"><i
          class="uil-trash-alt"></i> Sil</a>
    </div>
    <div :class="'inner_comment_' + comment.id" class="form-group comment_section text-right mb-0"
      style="display: none">
      <textarea class="form-control mt-2 text-left" rows="2" v-model="form.comment" @blur="setDisplayNone"
        :placeholder="$t('general.what_are_you_thinking')"></textarea>
      <button type="button" v-if="form.comment.length" @click="replyComment(true)" class="btn btn-sm btn-info">
        {{ $t("general.save") }}
      </button>
    </div>

    <ul v-if="comment.replies?.length > 0" class="pl-1 pt-4 uk-comment-list child_comment">
      <li v-for="(reply, index) in comment.replies" :key="'comment_item_' + reply.id + '_' + index">
        <CommentItem @reset="resetForm()" :current_user="current_user" :post_id="post_id" :post_index="post_index"
          @reply="replyComment($event)" :comment="reply"></CommentItem>
      </li>
    </ul>
  </article>
</template>

<script>
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import CommentItem from "@/view/components/wall/Comment";
import { DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";


export default {
  name: "CommentItem",
  components: { CommentItem },
  data() {
    return {
      form: {
        comment: "",
      },
    };
  },
  props: {
    comment: {
      required: true,
    },
    current_user: {
      required: true
    },
    post_id: {
      required: true,
    },
    post_index: {
      required: true,
    },
  },
  methods: {
    beforeCreate() {
      function registerStoreModule(moduleName, storeModule) {
        if (!(store && store.state && store.state[moduleName])) {
          store.registerModule(moduleName, storeModule);
        }
      }
      registerStoreModule(MODULE_NAME, module);
      registerStoreModule(USER_MODULE_NAME, user_module);
    },
    deleteComment() {
      let id = this.comment.id

      let self = this;
      self.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `/api/comment/${id}`,
          acceptPromise: true,
        })
        .then((result) => {
          self.resetForm()
        });

      this.$emit('reset')
    },
    resetForm() {
      this.$emit('reset')
    },
    replyComment(buttonClick) {
      let data = buttonClick
      if (data === true)
        data = {
          text: this.form.comment,
          index: this.post_index,
          id: this.post_id,
          comment_id: this.comment.id
        }

      this.$emit('reply', data)
    },
    openCommentArea(eve) {
      let sibling = document.getElementsByClassName('inner_comment_' + this.comment.id)[0];
      sibling.style.display = "block";
      sibling.querySelector("textarea").focus();
    },
    setDisplayNone(eve) {
      setTimeout(() => {
        this.form.comment = "";
        eve.target.parentElement.style.display = "none";
      }, 200);
    },
    timeFromNow(date) {
      return moment(date).locale(this.secureLocalStorage("language") === 'en' ?
        this.secureLocalStorage("language") + 'gb' : this.secureLocalStorage("language")).fromNow();
    },
  },
};
</script>
<style>
ul.comment_tree_view li {
  margin-left: 15px;
  position: relative;
  padding-left: 5px;
}

ul.comment_tree_view li::before {
  content: " ";
  position: absolute;
  width: 1px;
  background-color: #ddd;
  top: 15px;
  height: calc(100% + 70px);
  bottom: -12px;
  left: -10px;
}

ul.comment_tree_view .child_comment li:not(:only-child):first-child::before {
  top: -20px;
  height: calc(100% + 105px);
}

ul.comment_tree_view li:not(:first-child):last-child::before {
  display: none;
}

ul.comment_tree_view li:only-child::before {
  display: list-item;
  content: " ";
  position: absolute;
  width: 1px;
  background-color: #ddd;
  top: -20px;
  bottom: 7px;
  height: 35px;
  left: -10px;
}

ul.comment_tree_view.parent_comment_tree_view>li:only-child::before,
ul.comment_tree_view.parent_comment_tree_view>li:only-child::after {
  display: none;
}

ul.comment_tree_view li::after {
  content: " ";
  position: absolute;
  left: -10px;
  width: 10px;
  height: 1px;
  background-color: #ddd;
  top: 15px;
}
</style>
